.nav {
  position: fixed;
  width: 100%;
  height: 5rem;
  padding: 0 2rem;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
  z-index: 9;
}

@media only screen and (max-width: 425px) {
  .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.nav.scroll {
  height: 3rem;
  background: #273469;
}

.nav .nav-icon {
  -webkit-box-flex: 6;
      -ms-flex: 6;
          flex: 6;
}

@media only screen and (max-width: 425px) {
  .nav .nav-icon {
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
  }
}

.nav .nav-icon a:hover {
  color: white;
}

.nav .nav-items {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

@media only screen and (max-width: 425px) {
  .nav .nav-items {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

.nav .nav-items li {
  list-style-type: none;
  font-size: 1.2em;
}

.nav a {
  text-decoration: none;
  color: white;
}

.nav a:hover {
  color: #ffec51;
}

.nav-course {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  color: white;
}

.nav-course:hover {
  color: #ffec51;
}

.nav-course:hover a {
  color: #ffec51;
}

.nav-course .chev {
  margin: 0 2px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.nav-course .chev.open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.courses-dropdown {
  background: #273469;
  position: absolute;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
}

.courses-dropdown.start {
  max-height: 500px;
}

.courses-dropdown li {
  list-style-type: none;
  font-size: 1.2em;
  padding: 0.5rem;
}

.mobile-main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 5rem;
}

.mobile-menu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  top: 3rem;
  background: #273469;
}

.mobile-menu a {
  padding: 1rem 0;
}

.mobile-menu .chev {
  margin: 0.8rem 0;
}
