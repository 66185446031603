.course-header {
  margin: 0px;
}

.course-header img {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.course-header h1 {
  position: absolute;
  text-transform: uppercase;
  font-size: 3rem;
  width: 100%;
  top: 50%;
  text-align: center;
  color: white;
  z-index: 2;
}

.body {
  margin: 0 4rem;
}

@media only screen and (max-width: 425px) {
  .body {
    margin: 2rem;
  }
}

.body .body-points {
  margin: 4rem 0;
  text-align: center;
  text-transform: uppercase;
}

.body .body-points h2 {
  margin: 0 0 2rem 0;
  font-size: 3rem;
}

@media only screen and (max-width: 425px) {
  .body .body-points h2 {
    font-size: 2rem;
  }
}

.body .body-points .point-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
  grid-gap: 4rem;
}

@media only screen and (max-width: 425px) {
  .body .body-points .point-container {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
}

@media only screen and (max-width: 770px) and (min-width: 425px) {
  .body .body-points .point-container {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
}

.body .body-main {
  margin: 4rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .body .body-main {
    margin: 0;
  }
}

.body .body-main h2 {
  text-transform: uppercase;
  font-size: 4rem;
  margin: 2rem;
}

@media only screen and (max-width: 425px) {
  .body .body-main h2 {
    font-size: 2rem;
    margin: 2rem 0;
  }
}

.body .body-main h2 span {
  color: #87cefa;
}

.body .body-main p {
  text-align: left;
  font-size: 1.5rem;
}

@media only screen and (max-width: 425px) {
  .body .body-main p {
    font-size: 1rem;
  }
}

.point {
  background: lightgrey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.point p {
  padding: 2rem;
}

@media only screen and (max-width: 425px) {
  .point p {
    padding: 0;
    font-size: 0.2rem;
  }
}

.point::before {
  content: "";
  padding-top: 100%;
}

.mini-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 1rem;
  background: #87cefa;
}

.mini-gallery img {
  height: 30vh;
  width: 30vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (max-width: 425px) {
  .mini-gallery img {
    height: 10vh;
    width: 10vh;
  }
}

@media only screen and (max-width: 770px) and (min-width: 425px) {
  .mini-gallery img {
    height: 15vh;
    width: 15vh;
  }
}

.gallery {
  position: fixed;
  top: 10%;
  left: 10%;
  background: white;
  border: 1rem solid white;
}

.gallery img {
  width: 75vw;
  height: 75vh;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.gallery .gallery-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 2;
}

.gallery .gallery-nav.right {
  right: 0;
}

.gallery .gallery-nav .right:hover {
  color: #87cefa;
}

.gallery .gallery-nav .left:hover {
  color: #87cefa;
}

.gallery .gallery-nav .disable {
  color: lightgrey;
}

.gallery .gallery-nav .disable:hover {
  color: lightgrey;
}
