.about-us .padding {
  background: #273469;
  height: 5rem;
}

.about-us h1 {
  padding: 2rem;
  background: #273469;
  color: white;
}

.about-us p {
  margin: 2rem;
  font-size: 1.5rem;
}
