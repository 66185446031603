.intro {
  margin: 4rem;
}

.intro h3 {
  text-decoration: underline;
  margin: 2rem 0;
}

.intro .about {
  padding: 4rem;
  background-image: url("../../images/intro.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .intro .about {
    padding: 2rem;
  }
}

.intro .about .intro-text {
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  padding: 1rem;
}

.intro .about .intro-text h3 {
  font-size: 2rem;
  color: white;
}

.intro .about .intro-text p {
  font-size: 1.5rem;
  line-height: 2;
  color: white;
}

@media only screen and (max-width: 425px) {
  .intro .about .intro-text p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.intro .split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 4rem 0;
}

.intro .split .split-text {
  -webkit-box-flex: 6;
      -ms-flex: 6;
          flex: 6;
  margin: 4rem;
}

@media only screen and (max-width: 425px) {
  .intro .split .split-text {
    margin: 0;
    text-align: center;
  }
}

.intro .split .split-text h3 {
  font-size: 1.5rem;
  margin: 0 0 2rem 0;
}

.intro .split .split-text p {
  font-size: 1.2rem;
  line-height: 2;
}

@media only screen and (max-width: 425px) {
  .intro .split .split-text.left {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2rem;
    background-image: url("../../images/left.jpg");
  }
}

@media only screen and (max-width: 425px) {
  .intro .split .split-text.right {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2rem;
    background-image: url("../../images/right.jpg");
  }
}

@media only screen and (max-width: 425px) {
  .intro .split .split-text .split-text-content {
    -webkit-backdrop-filter: blur(1rem);
            backdrop-filter: blur(1rem);
  }
}

.intro .split .split-img {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 4rem;
}

.intro .split .split-img::before {
  content: "";
}

.intro .split .split-img.right {
  background-image: url("../../images/right.jpg");
}

.intro .split .split-img.left {
  background-image: url("../../images/left.jpg");
}

.courses {
  margin: 4rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .courses {
    margin: 2rem;
  }
}

.courses h2 {
  margin: 0 0 4rem 0;
}

.courses .course-cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
}

.courses .course-cards a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 425px) {
  .courses .course-cards {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
}

.card {
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.card .card-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card .card-image h3 {
  height: 100%;
  font-size: 2rem;
  color: white;
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  z-index: 2;
}

@media only screen and (max-width: 425px) {
  .card .card-image h3 {
    font-size: 1.2rem;
  }
}

.card .card-image::before {
  content: "";
  display: block;
  padding-top: 25%;
}

.card .card-image::after {
  content: "";
  display: block;
  padding-bottom: 25%;
}

.card p {
  height: 10rem;
  padding: 1rem;
}

@media only screen and (max-width: 425px) {
  .card p {
    font-size: 1.2rem;
  }
}

.card:hover {
  -webkit-box-shadow: 1px 8px 20px grey;
          box-shadow: 1px 8px 20px grey;
}
