* {
  border: 0;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  width: 100%;
  font-family: "Lato", sans-serif;
}

html {
  scroll-behavior: smooth;
}
