.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background: #273469;
  padding: 2rem;
}

@media only screen and (max-width: 425px) {
  .footer {
    padding: 1rem;
    font-size: 0.5rem;
  }
}

.footer p {
  color: white;
}

.contact {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.contact a {
  color: white;
}

.contact a:hover {
  color: #ffec51;
}

.inquire {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media only screen and (max-width: 425px) {
  .inquire {
    padding: 0 1rem;
  }
}

.inquire #message {
  resize: none;
}

.inquire input {
  width: 100%;
  height: 2rem;
}

@media only screen and (max-width: 425px) {
  .inquire input {
    margin-bottom: 1rem;
  }
}

.inquire textarea {
  width: 100%;
  margin: 0 0 1rem 0;
}

.inquire button {
  background: #87cefa;
  padding: 0.5rem 1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.inquire button:hover {
  background: #ffec51;
}

.inquire label {
  color: white;
  margin: 1rem 0;
}

@media only screen and (max-width: 425px) {
  .inquire label {
    font-size: 0.5rem;
  }
}

.inquire #email {
  margin: 0 0 1rem 0;
}

.inquire .form-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.inquire .form-contact #name {
  width: 45%;
}

@media only screen and (max-width: 425px) {
  .inquire .form-contact #name {
    width: 100%;
  }
}

.inquire .form-contact #contact {
  width: 45%;
}

@media only screen and (max-width: 425px) {
  .inquire .form-contact #contact {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .inquire .form-contact {
    display: inline;
  }
}
