.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel .carousel-nav {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  z-index: 2;
}

.carousel .carousel-nav.right {
  right: 0;
}

.carousel .carousel-nav:hover {
  color: #ffec51;
}

.carousel .front {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
}

.carousel .front h1 {
  font-size: 4rem;
  color: white;
}

@media only screen and (max-width: 425px) {
  .carousel .front h1 {
    font-size: 3rem;
    text-align: center;
  }
}

.carousel .front .button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  height: 10%;
  width: 25%;
  color: #273469;
  background: #273469;
  text-transform: uppercase;
  font-size: 1.5rem;
  border-radius: 1rem;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .carousel .front .button {
    font-size: 1rem;
  }
}

.carousel .front .button a {
  text-decoration: none;
  color: white;
  position: relative;
  -webkit-transition: all 0.2s ease-Out;
  transition: all 0.2s ease-Out;
  padding: 1rem 2rem;
}

.carousel .front .button .circle {
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 100%;
  background: #ffec51;
  position: absolute;
  -webkit-transition: all 0.2s ease-Out;
  transition: all 0.2s ease-Out;
}

.carousel .front .button:hover .circle {
  width: 200%;
  height: 200%;
  opacity: 1;
}

.carousel .front .button:hover a {
  color: #273469;
}

.slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw);
}

.slider .slide {
  display: inline-block;
}

.slider .slide h2 {
  position: absolute;
  text-align: center;
  font-size: 2rem;
  padding: 0 10rem;
  width: 100%;
  top: 50%;
  color: white;
  z-index: 2;
}

@media only screen and (max-width: 425px) {
  .slider .slide h2 {
    font-size: 1.5rem;
    padding: 0 2rem;
  }
}

.slider img {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.slider .prev.scroll-left {
  -webkit-animation-name: scroll-left;
          animation-name: scroll-left;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.slider .current.scroll-left {
  -webkit-animation-name: scroll-left;
          animation-name: scroll-left;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.slider .current.scroll-right {
  -webkit-animation-name: scroll-right;
          animation-name: scroll-right;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.slider .next.scroll-right {
  -webkit-animation-name: scroll-right;
          animation-name: scroll-right;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

@-webkit-keyframes scroll-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
